<template>
  <div class="app-main">
    <!-- 轮播图模块 -->
    <el-carousel :interval="5000" arrow="always" height="25dvw">
      <el-carousel-item>
        <img class="slide" src="@/assets/img/2.jpg" style="width:100%" alt="2">
      </el-carousel-item>
      <el-carousel-item>
        <img class="slide" src="@/assets/img/3.jpg" style="width:100%" alt="2">
      </el-carousel-item>
    </el-carousel>
    <div class="app-container">
    <!-- 文章模块 -->
    <div class="app-blurb">
      <h2 class="bt">公司简介</h2>
      <el-row :gutter="24">
        <el-col :xs="24" :sm="12" :md="12" :lg="12">
          <div><img src="@/assets/img/1-1P413152Aba.png" alt="公司发展"></div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12">
          <div class="content">
            <p>秦皇岛精方健康管理有限公司是我国首家“中医互联网+”模式落地的实践者。现有分院三家、中医智慧康养社区4家、培训基地一座、
            智慧康养驿站6个，同时2019年将在秦皇岛市区成立19家中医智慧康养社区，与山海关人民医院、开发区医院和开发区第二医院家联合成立3家医养康复中心，
            在首都北京成立智慧康养工作驿站150个。公司将以秦皇岛为依托，协同京津冀一体化发展，规划在未来3到5年内上市，实现全国2000家以上的连锁规模，
            打造中医医养康复领域航母旗舰品牌...</p>
          </div>
        </el-col>
      </el-row>
      <div class="more"><router-link to="/blurb">了解更多</router-link></div>
    </div>
    <div class="brand">
      <h2 class="bt">品牌项目</h2>
      <el-row :gutter="24" >
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <a>
            <div class="imgbox"><img src="@/assets/img/1-1ZR315344A09.jpg" alt="急性扭挫伤健康管理"></div>
            <span>急性扭挫伤健康管理</span>
          </a>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <a>
            <div class="imgbox"> <img src="@/assets/img/1-1ZR315342V40.jpg" alt="疾病后期复健"> </div>
            <span>疾病后期复健</span>
          </a>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <a>
            <div class="imgbox"> <img src="@/assets/img/1-1ZR3153404364.jpg" alt="肩周炎健康管理"> </div>
            <span>肩周炎健康管理</span>
          </a>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <a>
            <div class="imgbox"> <img src="@/assets/img/1-1ZR3153255K4.jpg" alt="乳腺健康管理"> </div>
            <span>乳腺健康管理</span>
          </a> 
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <a>
            <div class="imgbox"> <img src="@/assets/img/1-1ZR315322I09.jpg" alt="全年亚健康调理"> </div>
            <span>全年亚健康调理</span>
          </a>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <a>
            <div class="imgbox"> <img src="@/assets/img/1-1ZR3153211443.jpg" alt="青少年近视健康管理"> </div>
            <span>青少年近视健康管理</span>
          </a> 
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <a>
            <div class="imgbox"> <img src="@/assets/img/1-1ZR315315B26.jpg" alt="脾胃健康管理"> </div>
            <span>脾胃健康管理</span>
          </a>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <a>
            <div class="imgbox"> <img src="@/assets/img/1-1ZR315313OJ.jpg" alt="慢性疲劳综合症调理"> </div>
            <span>慢性疲劳综合症调理</span>
          </a> 
        </el-col>
      </el-row>
      
    </div>
    <div class="regimen">
      <h2 class="bt">养生知识</h2>
      <el-row :gutter="24" >
        <el-col :xs="24" :sm="12" :md="12" :lg="12">
          <a class="item">
            <div class="left">
              <h4>八段锦1-两手托天理三焦</h4>
              <p>...</p>
              <span>MORE+</span>
            </div>
            <div class="right">
              <p class="num">02</p>
              <span>2019-09</span>
            </div>
          </a>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12">
          <a class="item">
            <div class="left">
              <h4> 八段锦-第二式 左右开弓似射雕</h4>
              <p>...</p>
              <span>MORE+</span>
            </div>
            <div class="right">
              <p class="num">02</p>
              <span>2019-09</span>
            </div>
            </a>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12">
          <a class="item">
            <div class="left">
              <h4>女性三个地方“毛”越浓密越健康？</h4>
              <p>身上长体毛是非常正常的一件事情，男性身上的体毛大多数都特别的浓密，但是如果女人身上...</p>
              <span>MORE+</span>
            </div>
            <div class="right">
              <p class="num">19</p>
              <span>2019-08</span>
            </div>
            </a>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12">
          <a class="item">
            <div class="left">
              <h4>每天锻炼1小时，健康生活1辈子</h4>
              <p>为了满足广大人民群众日益增长的体育需求，为了纪念北京奥运会成功举办，从2009年起， 每年...</p>
              <span>MORE+</span>
            </div>
            <div class="right">
              <p class="num">19</p>
              <span>2019-08</span>
            </div>
            </a>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12">
          <a class="item">
            <div class="left">
              <h4>快来瞧一瞧看一看，大暑有什么风俗习俗？</h4>
              <p>大暑来临，一年中最热的时候，这个时节里，我国民间有哪些习俗呢？ 一、晒伏姜 整个三伏天...</p>
              <span>MORE+</span>
            </div>
            <div class="right">
              <p class="num">19</p>
              <span>2019-08</span>
            </div>
            </a>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12">
          <a class="item">
            <div class="left">
              <h4>衣物上膏药的清洗妙招</h4>
              <p>有时衣服蹭上了膏药可以用酒精加几滴水（或用高粱酒亦可），放在沾有膏药渍的地方搓揉，...</p>
              <span>MORE+</span>
            </div>
            <div class="right">
              <p class="num">13</p>
              <span>2018-04</span>
            </div>
            </a>
        </el-col>
      </el-row>
      <div class="more"><router-link to="/regimen">查看更多</router-link></div>
    </div>     
    <div class="honor">
      <h2 class="bt">资质荣誉</h2>
      <el-row :gutter="24" >
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <img src="@/assets/img/honor/3344.jpg" alt="">
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <img src="@/assets/img/honor/4455.jpg" alt="">
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <img src="@/assets/img/honor/1-1ZR0132P2523.jpg" alt="">
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <img src="@/assets/img/honor/1-1ZR0132R1W3.jpg" alt="">
        </el-col>
      </el-row>
      <div class="more"><router-link to="/honor">查看更多</router-link></div>
    </div>        
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  methods: {
    // 跳转到文章详情页
    toList(id) {
      // console.log(id);
      // 采用编程式路由跳转
      this.$router.push({
        path: "/list",
        query: { id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-blurb {
  padding: 30px 0 20px 0;
  img {
    width: 100%;
    max-width: 280px;
    display: block;
    margin: 20px auto;
  }
  .content {
    p {
      color: #333333;
      font-size: 1.125rem;
      line-height: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
      margin: 1.5rem 0;
      overflow: hidden;
      text-indent: 34px;
    }
  }
}
.honor{
  img{
    width: 100%;
    padding: 5px;
    display: block;
  }
}
.regimen{
  .item{
    background: #fff;
    margin: 10px;
    display: flex;
    .left{
      width: 72%;
      border-right: 1px solid #ac7a4c;
      padding: 20px;
      h4{
        font-weight: bold;
        margin-bottom: 1%;
        color: #ac7a4c;
      }
      p{

      }
      span{
        font-size: 0.625rem;
        color: #ac7a4c;
        margin-top: 2%;
      }
    }
    .right{
      width: 15%;
      color: #dec6af;
      padding: 20px;
      .num{
        font-size: 3.5rem;
        line-height: 4.5rem;
        border-bottom: 1px solid #dec6af;
      }
      span{

      }
    }
  }
}

</style>